import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import locales from "../../constants";

const FarCry = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";

  return (
    <Layout
      seo={{
        title: "Far Cry 6",
        href: slug,
        lang: "pl",
        // ogImage: require("../../assets/img/portfolio/far-cry_og_image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #9c5e01, #db8d15, #fba624, #ffae34, #fab042 )",
        icons: "#ffae34",
        navClass: "far-cry__nav",
        ogImage: require("../../assets/img/portfolio/far-cry_top_back.jpg"),
      }}
      //   langSwitch={{
      //     langKey: "en",
      //     langSlug: "/en/projects/far-cry/",
      //   }}
    >
      <PortfolioHeader name="far-cry" />
      <div className="far-cry">
        <section
          className="portfolio-intro far-cry-intro far-cry-section far-cry-section__intro"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Far Cry 6</h1>
                <ul>
                  <li>Strona internetowa</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  Far Cry 6 to najnowsza część jednego z najbardziej
                  rozpoznawalnych i popularnych tytułów z gatunku strzelanek
                  FPS. Gra francuskiego potentata, firmy Ubisoft znanego również
                  z takich tytułów jak Assassin's Creed, Just Dance, The
                  Settlers, Watch Dogs, Rayman czy Prince of Persia to jedna z
                  największych premier 2021 roku, dostępna na PS4, PS5, XONE,
                  XSX oraz PC.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="far-cry-section far-cry-section__concrete">
          <div className="container-fluid">
            <h2>Konkret</h2>
            <p>
              Nasz Klient - <strong>GRY-Online</strong> to legenda polskiego
              dziennikarstwa gamingowego. Krakowska spółka akcyjna, będąca od
              kilku lat częścią francuskiego potentata medialnego Webedia,
              prowadzi nie tylko będący mekką dla graczy, kultowy i niezwykle
              popularny serwis internetowy <strong>gry-online.pl</strong>, ale
              również największy w Polsce redakcyjny kanał na YouTube poświęcony
              tematyce gier - <strong>TVGRYpl</strong> (<span>~</span>1,3
              miliona subskrypcji). Ekipa GOLa zgłosiła się do nas z projektem
              zrealizowania strony konkursowej promującej w Polsce XBOXową
              wersję gry.
            </p>
          </div>

          <div className="scroll_wrapper">
            <div className="img_wrapper">
              <picture>
                <source
                  type="image/avif"
                  srcSet={require("../../assets/img/portfolio/far-cry_fullpage.avif")}
                  loading="lazy"
                  alt="Projekt strony Far Cry 6"
                />
                <img
                  src={require("../../assets/img/portfolio/far-cry_fullpage.jpg")}
                  loading="lazy"
                  alt="Projekt strony Far Cry 6"
                />
              </picture>
            </div>
          </div>
        </section>

        <section className="far-cry-section far-cry-section__task">
          <div className="container-fluid pb-0">
            <div className="row">
              <div className="col-12 col-md-6 d-flex align-items-center">
                <picture>
                  <source
                    type="image/avif"
                    srcSet={require("../../assets/img/portfolio/far-cry_smartphone.avif")}
                    className="far-cry__smartphone"
                    loading="lazy"
                    alt="Smartphone"
                  />
                  <img
                    src={require("../../assets/img/portfolio/far-cry_smartphone.png")}
                    className="far-cry__smartphone"
                    loading="lazy"
                    alt="Smartphone"
                  />
                </picture>
              </div>
              <div className="col-12 col-md-6">
                <h2>Zadanie:</h2>
                <ol>
                  <li>Zaprojektować stronę w oparciu o jej key art</li>
                  <li>
                    Jak najwierniej nawiązać rozwiązaniami wizualnymi do
                    oficjalnej strony gry
                  </li>
                  <li>
                    Zaprojektować stronę w taki sposób, aby sprawnie uzyskać
                    akceptację producenta - Ubisoft
                  </li>
                  <li>
                    Zaprojektować stronę w taki sposób, aby uzyskać akceptację
                    właściciela marki XBOX - firmy Microsoft
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="far-cry-section far-cry-section__newspapers">
          <picture className="img-fluid d-none d-md-block">
            <source
              type="image/avif"
              srcSet={require("../../assets/img/portfolio/far-cry_plate.avif")}
              loading="lazy"
              alt="Śmieci i tabliczka"
            />
            <img
              src={require("../../assets/img/portfolio/far-cry_plate.png")}
              loading="lazy"
              alt="Śmieci i tabliczka"
            />
          </picture>
          <div className="far-cry__newspapers"></div>
          <div className="far-cry__newspapers--sepia"></div>
        </section>

        <section className="far-cry-section far-cry-section__effect">
          <div className="container-fluid">
            <div className="row far-cry-effect">
              <div className="col-12 pr-4 order-1">
                <h2>Efekt:</h2>
                <div className="far-cry-effect__point">
                  <span>1.</span>
                  <p>
                    Key Art jest wykorzystywany wszędzie, nawiązując do
                    przedstawionego w grze konfliktu dwóch stron
                    (dyktator/rebelianci) wykorzystaliśmy go do płynnego
                    wprowadzenia użytkowników w podzielony świat gry obligując
                    ich do opowiedzenia się po jednej ze stron konfliktu.
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-6 far-cry-effect__screen order-2">
                <picture>
                  <source
                    type="image/avif"
                    srcSet={require("../../assets/img/portfolio/far-cry_prize.avif")}
                    loading="lazy"
                    alt="Strona sekcja nagroda"
                  />
                  <img
                    src={require("../../assets/img/portfolio/far-cry_prize.jpg")}
                    loading="lazy"
                    alt="Strona sekcja nagroda"
                  />
                </picture>
              </div>

              <div className="col-12 col-md-6 far-cry-effect__screen order-3">
                <picture>
                  <source
                    type="image/avif"
                    srcSet={require("../../assets/img/portfolio/far-cry_contest.avif")}
                    loading="lazy"
                    alt="Strona sekcja nagroda"
                  />
                  <img
                    src={require("../../assets/img/portfolio/far-cry_contest.jpg")}
                    loading="lazy"
                    alt="Strona sekcja nagroda"
                  />
                </picture>
              </div>

              <div className="col-12 col-md-6 far-cry-effect__macbook d-flex align-items-center order-5 order-md-4">
                <picture>
                  <source
                    type="image/avif"
                    srcSet={require("../../assets/img/portfolio/far-cry_macbook.avif")}
                    loading="lazy"
                    alt="Laptop z włączoną stroną"
                  />
                  <img
                    src={require("../../assets/img/portfolio/far-cry_macbook.png")}
                    loading="lazy"
                    alt="Laptop z włączoną stroną"
                  />
                </picture>
              </div>

              <div className="col-12 col-md-6 order-4 order-md-5">
                <div className="far-cry-effect__point">
                  <span>2.</span>
                  <p className="pr-5">
                    Klientowi zależało na tym, aby wizualnie strona była jak
                    najbardziej zbliżona do oficjalnej strony gry. Głównym
                    założeniem było to, aby jak najwierniej odwzorować mechanizm
                    pokazywania treści w gazetach - raz z perspektywy pro a raz
                    anty dyktatorskiej. Meldujemy wykonanie zadania.
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-6 order-6">
                <div className="far-cry-effect__point">
                  <span>3.</span>
                  <p>
                    Akceptacje zarówno ze strony Ubisoft oraz Microsoft
                    otrzymaliśmy już w pierwszym podejściu (duma), niemniej
                    największą satysfakcję dało nam zadowolenie ekipy Gry-OnLine
                    z która kontynuujemy współpracę w kolejnych gamingowych
                    projektach. GG WP
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 far-cry-effect__banner align-items-center order-7">
                <picture>
                  <source
                    type="image/avif"
                    srcSet={require("../../assets/img/portfolio/far-cry_banner.avif")}
                    loading="lazy"
                    alt="Napis Far Cry 6"
                  />
                  <img
                    src={require("../../assets/img/portfolio/far-cry_banner.jpg")}
                    loading="lazy"
                    alt="Napis Far Cry 6"
                  />
                </picture>
              </div>
            </div>
          </div>
        </section>
      </div>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default FarCry;
